body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
  display: flex;
  gap: 10;
  flex-wrap: wrap;
}

.mr {
  margin-right: 20px;
}

.mb-row {
  margin-left: -400px;
}

.mg-t {
  margin-top: -600px;
}

@media screen and (max-width:1200px) {
  .mb-row {
    margin-left: 0;
  }

  .mg-t {
    margin-top: -100px;
  }

  .flex {
    margin-bottom: 30px;
  }
}